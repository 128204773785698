<template>
  <v-card class="mt-5" flat>
    <v-card-text>
      <v-card-title class="text-center justify-center py-6">
        <h3 class="font-weight-bold display-1">Documentos Operador:</h3>
      </v-card-title>
      <v-row>
        <v-col cols="12">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Documento Original</th>
                  <th class="text-center">Título</th>
                  <th class="text-left">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in documentos" :key="index">
                  <td class="text-left">
                    {{ item.nombre }}
                  </td>
                  <td class="text-left">
                    {{ item.titulo || ' - ' }}
                  </td>
                  <td class="text-left">
                    <div class="d-flex justify-space-between">
                      <v-btn
                        :disabled="!item.id"
                        v-on:click="verDoc(item.url)"
                        color="success"
                      >
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                      <v-btn
                        :disabled="!item.id"
                        v-on:click="eliminarDoc(item.id)"
                        color="error"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

      <v-row class="mt-5">
        <v-col cols="12">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Título</th>
                  <th class="text-left">Documento Original</th>
                  <th class="text-left">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-left">
                    <v-text-field
                      v-model="nuevoDocumento.titulo"
                      label="Título"
                      outlined
                      dense
                    ></v-text-field>
                  </td>

                  <td class="text-left">
                    <v-file-input
                      v-model="nuevoDocumento.value"
                      :messages="['Tamaño Máximo: 10MB']"
                      label="Subir archivo"
                      outlined
                      dense
                      show-size
                    ></v-file-input>
                  </td>
                  <td class="text-left">
                    <v-btn
                      :disabled="!nuevoDocumento.value"
                      v-on:click="
                        subirDoc(nuevoDocumento.value, nuevoDocumento.titulo)
                      "
                      color="primary"
                    >
                      <v-icon>mdi-upload</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment';
import { API_URL } from '@/common/config';
import { showSimpleMessage } from '../../common/util';

const axios = require('axios');

export default {
  name: 'SubirDocumento',
  props: ['legajo', 'operatoriaId'],
  data() {
    return {
      token: localStorage.getItem('token'),
      documentos: [],
      formatos: [
        'jpg',
        'png',
        'jpeg',
        'pdf',
        'docx',
        'doc',
        'txt',
        'xls',
        'xlsx',
      ],
      nuevoDocumento: {
        titulo: '',
        descripcion: '',
        value: null, // Archivo seleccionado
        id: null,
        nombre: null,
        url: null,
        updatedAt: null,
        documentacion_tipo: null, // Este valor se definirá al agregar
      },
    };
  },
  methods: {
    transformDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },

    async subirDoc(documentoAdjunto, titulo) {
      const codigoDoc = titulo.replace(/\s/g, '');

      const extension = documentoAdjunto.name.split('.').pop().toLowerCase();

      if (documentoAdjunto.size > 10 * 1024 * 1024) {
        showSimpleMessage(
          'Alerta',
          'El documento excede el tamaño máximo de 10 MB.',
          'info'
        );
        throw new Error('El documento excede el tamaño máximo de 10 MB.');
      } else if (!this.formatos.includes(extension)) {
        showSimpleMessage(
          'Alerta',
          `Los formatos aceptados son: 'jpg', 'png', 'jpeg', 'pdf', 'docx', 'doc', 'txt', 'xls','xlsx' `,
          'error',
          5000
        );
        throw new Error('Error en el formato del archivo');
      }

      if (this.formatos.includes(extension)) {
        console.log('Subiendo video...');
      } else {
        console.log('Subiendo documento...');
      }

      const timeoutId = setTimeout(() => {
        this.mostrarMensajeTimeout = true;
      }, 10000); // Set a timeout of 10 seconds
      this.mostrarLoading = true;
      const url = `${API_URL}api/documentacionoperador`;
      const header = {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: this.token,
        },
      };

      const formData = new FormData();
      formData.append('nombre', codigoDoc);
      formData.append('lineacreditoclienteId', this.legajo);
      formData.append('titulo', this.nuevoDocumento.titulo);
      formData.append('adjunto', documentoAdjunto);

      axios
        .post(url, formData, header)
        .then((response) => {
          clearTimeout(timeoutId);
          this.mostrarLoading = false;
          if (response.data.status === 'error') {
            showSimpleMessage(
              'info',
              `Error :${response.data.message} `,
              'info',
              5000
            );
          }
          if (response.data.status === 'success') {
            showSimpleMessage(
              'Success',
              'El Documento se ha subido con exito',
              'info'
            );
            this.getDocumentacion();
            this.nuevoDocumento = {
              titulo: '',
              descripcion: '',
              value: null,
              id: null,
              nombre: null,
              url: null,
              updatedAt: null,
              documentacion_tipo: null,
            };
          }
        })
        .catch((error) => {
          clearTimeout(timeoutId);
          this.mostrarLoading = false;
          showSimpleMessage(
            'Error',
            `No se pudo subir el documento: ${error}`,
            'error'
          );
        });
    },
    verDoc(url) {
      window.open(url);
    },
    async eliminarDoc(id) {
      const url = `${API_URL}api/documentacionoperador/${id}`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: this.token,
        },
      };
      try {
        const response = await axios.delete(url, header);
        if (response.data.status === 'success') {
          showSimpleMessage(
            'Éxito',
            'Documento eliminado correctamente',
            'success'
          );
          // Refrescar la lista de documentos después de eliminar uno
          this.getDocumentacion();
        }
      } catch (error) {
        showSimpleMessage('Error', 'Error al eliminar el documento', 'error');
      }
    },

    getDocumentacion() {
      const url = `${API_URL}api/documentacionoperador/${this.legajo}`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: this.token,
        },
      };
      axios.get(url, header).then((response) => {
        this.documentos = response.data.documentos;
      });
    },
  },
  created() {
    this.getDocumentacion();
  },
};
</script>

<style scoped>
.v-simple-table__wrapper {
  overflow-x: auto;
}
</style>
