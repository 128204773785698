<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td>Comprobante</td>
            <td>
              <v-file-input
                class="mt-5"
                label="Comprobante"
                :messages="['Tamaño Máximo: 30MB']"
                outlined
                dense
                show-size
                counter
                v-model="nombreArchivo"
                :rules="maxsize"
              ></v-file-input>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Tipo de comprobante:</td>
            <td>
              <v-autocomplete
                v-model="tipo_comprobante"
                :items="listTipoFactura"
                label="Seleccionar..."
                @change="setValidarCAE()"
                hide-no-data
                hide-selected
                :disabled="control"
              ></v-autocomplete>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Punto de Venta:</td>
            <td>
              <v-text-field
                :disabled="control"
                v-model="punto_venta"
              ></v-text-field>
            </td>
          </tr>
          <tr>
            <td>Número de comprobante:</td>
            <td>
              <v-text-field
                v-model="nro_comprobante"
                :disabled="control"
              ></v-text-field>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Fecha Comprobante:</td>
            <td>
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fecha_comprobante"
                    label=""
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fecha_comprobante"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </td>
          </tr>
          <tr>
            <td>Concepto:</td>
            <td>
              <v-text-field
                v-model="concepto"
                :disabled="control"
              ></v-text-field>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Neto IVA:</td>
            <td>
              <v-text-field-percent
                v-model="neto_iva"
                v-bind:properties="{
                  prefix: '$',
                  readonly: false,
                  disabled: control,
                  outlined: false,
                  clearable: true,
                  placeholder: '',
                }"
                v-bind:options="{
                  locale: 'es-AR',
                  length: 15,
                  precision: 2,
                  empty: null,
                }"
              ></v-text-field-percent>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Total:</td>
            <td>
              <v-text-field-money
                v-model="total"
                v-bind:properties="{
                  prefix: '$',
                  readonly: false,
                  disabled: control,
                  outlined: false,
                  clearable: true,
                  placeholder: '',
                }"
                v-bind:options="{
                  locale: 'es-AR',
                  length: 15,
                  precision: 2,
                  empty: null,
                }"
              ></v-text-field-money>
            </td>
          </tr>
          <tr>
            <td>CUIT Emisor:</td>
            <td>
              <v-text-field
                :disabled="control"
                v-model="cuit_emisor"
              ></v-text-field>
            </td>
          </tr>
          <tr>
            <td>Tipo Documento Receptor:</td>
            <td>
              <v-autocomplete
                v-model="doc_tipo_receptor"
                :items="tipodocsAFIP"
                :search-input.sync="search"
                label="Seleccionar..."
                hide-no-data
                hide-selected
                :disabled="control"
              ></v-autocomplete>
            </td>
          </tr>
          <tr>
            <td>Documento Receptor:</td>
            <td>
              <v-text-field
                :disabled="control"
                v-model="doc_nro_receptor"
              ></v-text-field>
            </td>
          </tr>
          <tr v-if="validarCAE">
            <td v-if="caecaicaea !== ''">{{ caecaicaea }}:</td>
            <td v-if="caecaicaea !== ''">
              <v-text-field :disabled="control" v-model="cae"></v-text-field>
              <v-btn text color="warning" @click="cambiarModo">CAMBIAR</v-btn>
            </td>
            <td class="pt-3 pb-3" v-if="caecaicaea === ''">
              Seleccionar Tipo:
            </td>
            <td class="pt-3 pb-3" v-if="caecaicaea === ''">
              <v-btn-toggle v-model="caecaicaea">
                <v-btn value="CAE">
                  <span class="hidden-sm-and-down">CAE</span>
                </v-btn>
                <v-btn value="CAEA">
                  <span class="hidden-sm-and-down">CAEA</span>
                </v-btn>
                <v-btn value="CAI">
                  <span class="hidden-sm-and-down">CAI</span>
                </v-btn>
              </v-btn-toggle>
            </td>
          </tr>
          <tr>
            <td>Rechazado:</td>
            <td><v-checkbox v-model="rechazado"></v-checkbox></td>
          </tr>
          <tr v-if="rechazado">
            <td>Motivo Rechazo:</td>
            <td><v-text-field v-model="motivo_rechazo"></v-text-field></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-btn
      :disabled="estado === 11"
      block
      color="primary"
      v-if="!validarCAE"
      @click="uploadFile()"
      :loading="loading"
    >
      Cargar comprobante
    </v-btn>
    <v-btn
      :disabled="estado === 11"
      block
      color="primary"
      v-if="validarCAE"
      @click="uploadFileCAE()"
      :loading="loading"
    >
      Validar y Cargar comprobante
    </v-btn>
  </div>
</template>
<script>
import { API_URL, TIPO_FACTURA } from '@/common/config';
import { showSimpleMessage } from '../../common/util';

const axios = require('axios');

export default {
  props: ['estado', 'ParametrosCliente'],
  data: () => ({
    menu1: false,
    listTipoFactura: [],
    search: '',
    loading: false,
    fileOff: true,
    uploadFileOn: false,
    deleteFileOn: false,
    tipo_comprobante: '',
    concepto: '',
    punto_venta: '',
    nro_comprobante: '',
    fecha_comprobante: '',
    cae: '',
    cuit_emisor: '',
    doc_tipo_receptor: 0,
    doc_nro_receptor: '',
    neto_iva: '',
    total: '',
    rechazado: false,
    motivo_rechazo: '',
    nombreArchivo: null,
    tipoDocsTrilay: [],
    tipodocsAFIP: [],
    tipo_doc: '',
    validarCAE: true,
    noValidanCAE: [
      81, 82, 83, 111, 180, 182, 183, 185, 186, 188, 189, 190, 191,
    ],
    token: localStorage.getItem('token'),
    caecaicaea: '',
    maxsize: [
      (value) =>
        !value ||
        value.size <= 30000000 ||
        'Avatar size should be less than 2 MB!',
    ],
  }),
  methods: {
    async uploadFile() {
      this.loading = true;
      const url = `${API_URL}api/comprobante/cargarArchivo`;
      const header = {
        headers: {
          token: this.token,
        },
      };
      const data = new FormData();
      data.append('lineaCreditoCliente_id', this.$route.params.id);
      data.append('pto_vta', this.punto_venta);
      data.append('tipo_comprobante', this.tipo_comprobante);
      data.append('nro_comprobante', this.nro_comprobante);
      data.append('fecha_comprobante', this.fecha_comprobante);
      data.append('concepto', this.concepto);
      data.append('neto_iva', this.neto_iva);
      data.append('total', this.total);
      data.append('cae', this.cae);
      data.append('cuit_emisor', this.cuit_emisor);
      data.append('doc_tipo_receptor', this.doc_tipo_receptor);
      data.append('doc_nro_receptor', this.doc_nro_receptor);
      data.append('rechazado', this.rechazado);
      data.append('motivo_rechazo', this.motivo_rechazo);
      data.append('adjunto', this.nombreArchivo);
      await axios.post(url, data, header).then((r) => {
        this.nombreArchivo = '';
        const { status, message } = r.data;
        if (status === 'success') {
          this.$swal.fire('Comprobante', message, 'success');
          this.nro_comprobante = '';
          this.tipo_comprobante = '';
          this.concepto = '';
          this.neto_iva = '';
          this.total = '';
          this.nombreArchivo = [];
          this.uploadFileOn = true;
          this.deleteFileOn = true;
          this.fileOff = false;
        } else if (status === 'error') {
          showSimpleMessage(
            'Error',
            `Error al cargar Archivo ${message}`,
            'error',
            5000
          );
          showSimpleMessage(
            'Error',
            `Error al cargar Archivo ${message}`,
            'error',
            5000
          );
        } else {
          showSimpleMessage(
            'Error',
            'El tamaño del archivo supera los 30MB',
            'error',
            5000
          );

          this.fileOff = true;
        }
        this.loading = false;
      });
    },
    resetFormFields() {
      this.nro_comprobante = '';
      this.tipo_comprobante = '';
      this.concepto = '';
      this.neto_iva = '';
      this.punto_venta = '';
      this.total = '';
      this.cuit_emisor = '';
      this.doc_nro_receptor = '';
      this.nombreArchivo = [];
      this.uploadFileOn = true;
      this.deleteFileOn = true;
      this.fileOff = false;
    },
    handleUploadResponse(response, mensaje) {
      const { status, message } = response.data;
      if (status === 'success') {
        showSimpleMessage(
          'Informe',
          'Comprobante cargado con exito',
          'info',
          2000
        );
        this.resetFormFields();
      } else if (
        status === 'error' ||
        message.code === 'E_EXCEEDS_UPLOAD_LIMIT'
      ) {
        mensaje[0] = 'Error al cargar Archivo';
        mensaje[1] =
          message.code === 'E_EXCEEDS_UPLOAD_LIMIT'
            ? 'El tamaño del archivo supera los 2MB'
            : 'El comprobante ya existe!!';
        mensaje[2] = '';
        this.fileOff = true;
      } else {
        showSimpleMessage('error', message, 'error', 5000);
        this.fileOff = true;
      }
    },
    setValidarCAE() {
      console.log('tipo_comprobante: ', this.tipo_comprobante);

      this.validarCAE = !this.noValidanCAE.includes(this.tipo_comprobante);

      // Log the result for debugging
      console.log('validarCAE: ', this.validarCAE);

      // You can add additional logic here if needed
      if (this.validarCAE) {
        // Actions to take when CAE validation is required
        console.log('CAE validation is required for this type of comprobante');
      } else {
        // Actions to take when CAE validation is not required
        console.log(
          'CAE validation is not required for this type of comprobante'
        );
      }
    },

    async uploadFileCAE() {
      try {
        this.loading = true;
        const url = `${API_URL}api/comprobante/cargarArchivo`;
        const header = { headers: { token: this.token } };
        const data = new FormData();
        data.append('lineaCreditoCliente_id', this.$route.params.id);
        data.append('pto_vta', this.punto_venta);
        data.append('tipo_comprobante', this.tipo_comprobante);
        data.append('nro_comprobante', this.nro_comprobante);
        data.append('fecha_comprobante', this.fecha_comprobante);
        data.append('concepto', this.concepto);
        data.append('neto_iva', this.neto_iva);
        data.append('total', this.total);
        data.append('cae', this.cae);
        data.append('caecaicaea', this.caecaicaea);
        data.append('cuit_emisor', this.cuit_emisor);
        data.append('doc_tipo_receptor', this.doc_tipo_receptor);
        data.append('doc_nro_receptor', this.doc_nro_receptor);
        data.append('rechazado', this.rechazado);
        data.append('motivo_rechazo', this.motivo_rechazo);
        data.append('adjunto', this.nombreArchivo);

        const urlComprobarCAE = `${API_URL}api/comprobante/comprobar_cae`;
        const fechacbte = this.fecha_comprobante.replace(/-/g, '');
        const cbte = {
          cbte_modo: this.caecaicaea,
          cuit_emisor: this.cuit_emisor,
          pto_vta: this.punto_venta,
          cbte_tipo: this.tipo_comprobante,
          cbte_nro: this.nro_comprobante,
          cbte_fch: fechacbte,
          imp_total: this.total,
          cod_autorizacion: this.cae,
          doc_tipo_receptor: this.doc_tipo_receptor,
          doc_nro_receptor: this.doc_nro_receptor,
        };
        const resultado = await axios.post(urlComprobarCAE, cbte, header);

        if (resultado.data.status === 'success') {
          const mensajes = resultado.data.stdout;
          const esAprobado = resultado.data.resultado === 'A';

          if (esAprobado) {
            const r = await axios.post(url, data, header);
            this.handleUploadResponse(r, mensajes);
          } else {
            showSimpleMessage(
              'Informe',
              `Comprobante Rechazado, ${mensajes[3]}\n${mensajes[4]}`,
              'error',
              2000
            );
          }
        } else {
          throw new Error(resultado.data.message);
        }
      } catch (error) {
        console.error(error);
        showSimpleMessage('Error', error.message, 'error', 2000);
      } finally {
        this.loading = false;
      }
    },
    cambiarModo() {
      this.caecaicaea = '';
    },
  },
  computed: {
    control() {
      /* if (this.nombreArchivo === '' || this.nombreArchivo === null) {
        return true;
      } */
      return false;
    },
  },
  watch: {
    nombreArchivo() {
      if (!this.nombreArchivo) {
        return;
      }

      const file = this.nombreArchivo;
      const extension = file.name.split('.').pop().toLowerCase();
      const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'png', 'docx', 'xlsx'];
      const maxSizeInBytes = 30 * 1024 * 1024; // 30 MB

      if (!allowedExtensions.includes(extension)) {
        showSimpleMessage(
          ' Info',
          `El formato ${extension} no está permitido. Por favor, use: ${allowedExtensions.join(
            ', '
          )}`,
          'info',
          5000
        );
        throw new Error(`Formato de archivo no válido: ${extension}`);
      }

      if (file.size > maxSizeInBytes) {
        const maxSizeInMB = maxSizeInBytes / (1024 * 1024);
        showSimpleMessage(
          ' Error',
          `El archivo excede el tamaño máximo de ${maxSizeInMB} MB.`,
          'error',
          5000
        );
        throw new Error(
          `El archivo excede el tamaño máximo de ${maxSizeInMB} MB.`
        );
      }

      // If we reach here, the file is valid
      console.log('Archivo válido:', file.name);
    },
  },
  created() {
    this.listTipoFactura = TIPO_FACTURA;
    const urlTrilay = `${API_URL}api/trilay/tipodoc`;
    axios.get(urlTrilay, { headers: { token: this.token } }).then((r) => {
      const tdAFIP = r.data.result[0].map((td) => ({
        value: td.codtipodocAFIP,
        text: td.nombre,
      }));
      this.tipodocsAFIP = tdAFIP;
      this.tipodocsAFIP.push({ value: '07', text: 'CI Mendoza' });
      /* eslint-disable prefer-destructuring */
      this.tipoDocsTrilay = r.data.result[0];
      const tipodoctrilay = this.ParametrosCliente.find(
        (e) => e.tipo_parametro_id.clave === 'tipo_de_documento'
      ).valor;
      const dnibeneficiario = this.ParametrosCliente.find(
        (e) => e.tipo_parametro_id.clave === 'dni_representante'
      ).valor;
      this.doc_nro_receptor = dnibeneficiario;
      const obj = this.tipoDocsTrilay.find(
        (cod) => cod.codtipodoc === parseInt(tipodoctrilay, 10)
      );
      this.doc_tipo_receptor = obj.codtipodocAFIP;
    });
  },
};
</script>
